/*
* 업무구분: 활동성과관리
* 화면 명: MSPAP301M
* 화면 설명: 일정관리
* 화면 접근권한: 모든 사용자
*/
<template> 
  <ur-page-container :show-title="true" title="일정관리" type="subpage" class="MSPAP301M-height" ref="headerTest">
    <ur-box-container direction="column" alignV="start" v-if="lv_IsMSPAP301M"  style="height:100%">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-schedule"  style="height:100%">
        <mo-scheduler ref="loginScheduler2" :events="lv_SchdlList2" displayPeriodUom="month" :displayPeriodCount="1" :showEmptyDay="true" v-if="lv_IsCalendar"
          :shrink="shrink" @period-changed="fn_PeriodChanged" @click-date="fn_SelectDate" style="height:100%"
          :holidays="[]" eventTop="30px" eventContentHeight="16px"
          @on-mode-change="fn_OnModeChange" :msg-today="lv_TextToday">
          <template #month-picker="{year, month}">
            <div>
              {{year}}년 {{month}}월
              <mo-icon xsmall>calendar</mo-icon>
            </div>
          </template>
        </mo-scheduler>

        <mo-scheduler ref="loginScheduler" :events="lv_SchdlList" displayPeriodUom="month" :displayPeriodCount="1" :showEmptyDay="true" v-if="lv_IsDaily"
          :shrink="shrink" @period-changed="fn_PeriodChanged" @click-date="fn_SelectDate" style="height:100%"
          :holidays="lv_Holidays" eventTop="30px" eventContentHeight="16px"
          @on-mode-change="fn_OnModeChange" :msg-today="lv_TextToday"
          @on-schedule-modify-click="fn_ChoiceSchedule"
          @on-schedule-delete-click="fn_ChoiceSchedule">
          <template #month-picker="{year, month}">
            <div>
              {{year}}년 {{month}}월
              <mo-icon xsmall>calendar</mo-icon>
            </div>
          </template>
          <template #schedule-detail="{dayEvent}">
            <div>
              <p v-if="dayEvent.startTime === null && dayEvent.endTime === null"><span>시간</span> : 종일</p>
              <p v-if="dayEvent.startTime !== null && dayEvent.endTime !== null"><span>시간</span> : {{ $cmUtil.convertTime(dayEvent.startTime) }} ~ {{ $cmUtil.convertTime(dayEvent.endTime) }}</p>
              <p><span>장소</span> : {{ dayEvent.place }}</p>
              <div class="ns-schedule-btn">
                <mo-button @click="fn_ChoiceSchedule('U', dayEvent)">수정</mo-button>
                <mo-button @click="fn_ChoiceSchedule('D', dayEvent)">삭제</mo-button>
              </div> 
            </div>
          </template>
        </mo-scheduler>

      </ur-box-container>

      <div class="floating-wrap ns-floating-wrap">
            <mo-floating-button color="red" class="ico-define" @click="fn_OpenMSPAP302D"><mo-icon icon="add" sm/></mo-floating-button>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import SpConstants from '@/config/constants/spConstants'
  import MSPAP302D from '@/ui/ap/MSPAP302D'
  import Msg from '@/systems/webkit/msg/msg'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP301M",
    screenId: "MSPAP301M",
    components: {
      'MSPAP302D': MSPAP302D
    },
    props: {
    // 밖에서 열리거나 꺼질때 새로 데이터를 매핑하기 위함.
    flag: {
      type: Boolean
    },
    // 변형되지 않을 오늘 Date Obj
    pToday: {
      type: Date,
      default () { return new Date() }
    }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
  created() {
    // FrameNavi 영역 선택 처리
    this.$BottomManager.fn_SetBottomNavigationSelectedMenu('calendar')
    this.fn_OnLoad()
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
  },
  mounted() {
    this.$bizUtil.insSrnLog('MSPAP301M')
    console.log('mount Start')
    // this.$refs.loginScheduler.$el.addEventListener('touchstart', this.fn_CalendatTouchStart)
    // this.$refs.loginScheduler.$el.addEventListener('touchend', this.fn_CalendatTouchEnd)
    // this.$refs.loginScheduler2.$el.addEventListener('touchstart', this.fn_CalendatTouchStart)
    // this.$refs.loginScheduler2.$el.addEventListener('touchend', this.fn_CalendatTouchEnd)
    let tmp = this.$route.params
    if (tmp.pSchdlYmd) {
      this.lv_ChoiceYmd = tmp.pSchdlYmd
    }
    if (this.lv_ChoiceYmd) {
      console.log(String(this.lv_ChoiceYmd))
      this.fn_OnChangeView('calendar', new Date(String(this.lv_ChoiceYmd)))
    }
    else if (Object.keys(tmp).length === 0 || tmp === undefined) {
      this.fn_OnChangeView('calendar', this.lv_Today)
    }
    console.log('this.$refs.loginScheduler.getScrollElementId()', this.$refs.loginScheduler.getScrollElementId())
    this.$refs.headerTest.setScrollElementId(this.$refs.loginScheduler.getScrollElementId())
  },
  beforeDestroy () {
    console.log('beforeDestroy call')
    // this.$refs.loginScheduler.$el.removeEventListener('touchstart', this.fn_CalendatTouchStart)
    // this.$refs.loginScheduler.$el.removeEventListener('touchend', this.fn_CalendatTouchEnd)
    // this.$refs.loginScheduler2.$el.removeEventListener('touchstart', this.fn_CalendatTouchStart)
    // this.$refs.loginScheduler2.$el.removeEventListener('touchend', this.fn_CalendatTouchEnd)
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    let t_LastPg = {}
    t_LastPg.name = 'MSPAP301M'
    t_LastPg.lv_IsMSPAP301M = this.lv_IsMSPAP301M
    window.vue.getStore('spStore').dispatch('LAST_PAGE', t_LastPg )
  }, 
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
  data() {
    return {
      DEBUG: false,
      lv_IsFirst: true,
      lv_IsMSPAP301M: true,
      lv_Is301302: false, // 301내부의 301가 띄워져있을경우 디바이스 백키를 처리하기 위한 변수.
      lv_IsMSPAP302D: false, // 302가 띄워져 있을경우 디바이스 백키를 처리하기 위한 변수.
      lv_HolidayList: [], // 처음 가져온 주말정보를 저장하는 리스트
      lv_Holidays: [], // holidayList 를 파싱하여 만든 객체 리스트.
      lv_StartSchdDay: '', // 달력 한 장의 시작일 3월달력의경우 2월마지막주의 첫날(일요일)
      lv_EndSchdDay: '', // 달력 한 장의 끝일 3월달력의경우 4월 첫주의 첫날(일요일)
      lv_SchdlList: [], // 301M에서 사용할 날짜객체리스트 MSPAP301M :events
      lv_SchdlList2: [], // 스마트폰용 캘린더뷰에서 사용할 날짜 객체리스트 fdp-scheduler :events
      lv_ChoiceYmd: moment(new Date()).format('YYYY-MM-DD'), // 선택된 날짜: 변경에따라 301의 포커싱도 이동.
      lv_FocusDate: null, // focusing 될 날짜.
      lv_PriodStart: '', // 3월의경우 3월1일
      lv_PeriodEnd: '', // 3월의경우 3월 31일
      lv_SelectedPeriod: new Date(), // 클릭이벤트 안나오게 월을 변경하기 위해 사용되는 변수
      lv_FcSchdlSno: 0, // 스케쥴추가시 사용될 번호.
      lv_MobslChnlCustId: '', // 모바일 영업채널고객ID
      lv_CustAlctnCmpgnId: '', // 고객배정캠페인ID
      lv_Today: new Date(), // 오늘날짜
      lv_IsShowMSPAP001P: false,
      lv_NowLoadingPrdSchdl: false,
      lv_NowLoadingHoliday: false,
      lv_FromWidget: false,
      lv_FromPeriod: false,
      lv_ChoiceFcSchdlSno: 0, // 업데이트 될 스케쥴 id
      lv_ChoiceMobslChnlCustId: '', // 일정추가 :pMobslChnlCustId
      lv_ModChoicedYmd: '', // 일정추가 :pChoiceYmd
      lv_Ignore: false, // 여러번 초기화 되는 것을 막기위한 변수.
      lv_UserInfo: {},
      shrink: false,
      lv_lv_Popup302: {}, // 일정추가 팝업을 열기위한 객체
      lv_AlertPop: {}, // 메시지 팝업을 열기위한 객체
      lv_ConfirmPop: {}, // Confrim 팝업을 열기위한 객체
      lv_301Schedule_Data: [], // 이 화면 내에서 일자를 표시하기위한 객체 (31개)
      lv_301HolidayList: [], // 이 화면 내에서 휴일을 표시하기 위한 객체 (Schedule데이터보다 먼저 세팅되어있여야함.)
      lv_IsDaily: true, // Show Daliy View
      lv_IsCalendar: false, // Show Calendar View
      lv_TextToday: '오늘',
      lv_TouchStart: {},
      lv_TouchEnd: {},
      lv_ChangeMonth: new Date()
      }
    },
  /************************************************************************************************
  * watch 함수 정의 영역
  ************************************************************************************************/
  watch: {
    lv_HolidayList () {
      this.lv_Holidays.splice(0, this.lv_Holidays.length)
      for (let i = 0; i < this.lv_HolidayList.length; i++) {
        // 법정공휴일 : 1 , 윤년/음력 : 2
        if (this.lv_HolidayList[i].holyCd === '1') {
          var tempClass = this.lv_HolidayList[i].slrcYmYmd
          this.lv_Holidays.push({date: tempClass, label: '', holidayClasses: '-pub-holiday', dayNumberClasses: 'holiday-txt-color', holyCd: 1})
        } else if (this.lv_HolidayList[i].holyCd === '2') {
          tempClass = this.lv_HolidayList[i].slrcYmYmd
          this.lv_Holidays.push({date: tempClass, label: this.lv_HolidayList[i].holyNm, holidayClasses: ['-pub-lunar'], dayNumberClasses: 'holiday-txt-color', holyCd: 2})
        }
      }
      console.log('lv_HolidayList', this.lv_HolidayList)
      this.fn_SetHoliDay()
    },
    getWidgetCngd () {
      let lv_Vm = this
      lv_Vm.lv_IsMSPAP301M = true
      lv_Vm.lv_IsMSPAP302D = false
    },
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    getWidgetCngd () {
      return window.vue.getStore('spStore').getters.getState.widFlg
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /*********************************************************
     * Function명: fn_OnLoad
     * 설명: 최초접근 초기화 프로세스
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OnLoad () {
      // 자식과 오늘 날짜를 공유하기 위한 변수
      console.log('onLoad Start')
      this.lv_Today = new Date()
      let lv_Vm = this
      // 006P에서 온경우
      let tmp = this.$route.params
      console.log('fn_onLoad() route.params ::', tmp)
      console.log(Boolean(tmp.pSchdlYmd))
      // if (tmp.pSchdlYmd) {
      //   this.lv_ChoiceYmd = tmp.pSchdlYmd
      //   this.fn_OnChangeView('calendar', this.lv_ChoiceYmd)
      // } else 
      if (tmp.intentType === 'widget') {
      // 위젯에서 온경우
      // 문제시 MenuManger.js에서 'widget' 검색.
        lv_Vm.lv_FromWidget = true
      } else if (window.vue.getStore('spStore').getters.getState.lastPage && window.vue.getStore('spStore').getters.getState.lastPage.name === 'MSPAP301M') {
      // 314 - 위젯 - 302 이후 백키로 돌아왔을때 상태값 처리
        // this.lv_IsMSPAP301M = window.vue.getStore('spStore').getters.getState.lastPage.isMSPAP301M
        this.lv_IsMSPAP302D = false
      } else {
        this.fn_GoToday()
      }
      this.fn_SendTodaysData()
    },
    /*********************************************************
     * Function명: fn_GoToday
     * 설명: 화면의 [오늘] 버튼 클릭시 실행될 프로세스
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_GoToday () {
      if (this.DEBUG) console.log('■MSPAP301M■■■■■■■■■ fn_GoToday')
      // if (this.DEBUG) debugger
      this.lv_Ignore = true
      this.lv_ChoiceYmd = moment(this.lv_Today).format('YYYY-MM-DD')
      this.lv_SelectedPeriod = new Date(this.lv_ChoiceYmd)
      this.lv_FocusDate = new Date()
    },
    /*********************************************************
     * Function명: fn_BackBtnClicked
     * 설명: 하드웨어 백키가 눌렸을때 실행될 메서드
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_BackBtnClicked () {
      if (this.DEBUG) alert('백키 눌림')
      if (this.DEBUG) alert(this.lv_IsMSPAP301M + ' ' + this.lv_IsMSPAP302D + ' ' + this.lv_Is301302)
      if (this.DEBUG) console.log('■MSPAP301M■■■■■■■■■ fn_BackBtnClicked')
      if (this.lv_FromWidget && window.history.length <= SpConstants.ROUTER_ROOT_LENGTH + 1) { // 위젯에서 처음으로 들어왔을때 실행될것.
        if (!this.lv_IsMSPAP301M && !this.lv_IsMSPAP302D && !this.lv_Is301302) {
          // 메인화면 fff
          this.$router.push(SpConstants.SP_HOME)
          this.$txTSSPS40S1()
        } else if (this.lv_IsMSPAP301M && !this.lv_IsMSPAP302D && !this.lv_Is301302) {
          this.$router.push(SpConstants.SP_HOME)
          this.$destroy()
        } else {
        }
      } else if (!this.lv_IsMSPAP301M && !this.lv_IsMSPAP302D && !this.lv_Is301302) {
        // 메인화면 fff
          this.$router.go(-1)
      } else if (this.lv_IsMSPAP301M && !this.lv_IsMSPAP302D && !this.lv_Is301302) {
        if (!this.lv_Is301001) {
          this.$router.go(-1)
        } else {
        }
      } else {
      }
    },
    /******************************************************************************
    * Function명 : fn_SendTodaysData
    * 설명       : 오늘 데이터를 보냄
    ******************************************************************************/
    fn_SendTodaysData () {
      if (this.DEBUG) console.log('■MSPAP301M■■■■■■■■■ fn_SendTodaysData')
      var lv_Vm = this
      var trnstId = 'txTSSAP20S3'
      var pParam = {
        userEno: lv_Vm.getStore('userInfo').getters.getUserInfo.userId,
        fcSchdlStrYmd: moment(new Date()).format('YYYYMMDD'),
        fcSchdlEndYmd: moment(new Date()).format('YYYYMMDD')
      }
      console.log('fn_SendTodaysData', pParam)
      lv_Vm.post(lv_Vm, pParam, trnstId, 'S')
        .then(function (response) {
          if (lv_Vm.DEBUG) console.log('DEBUG====', response)
          if ( response.body != null) {
            if (lv_Vm.DEBUG) console.log(response.body.aPSchdlInfoSVO)
            lv_Vm.aPDaySchdlListSVO = response.body.aPSchdlInfoSVO
            lv_Vm.aPDaySchdlListSVO.sort((a, b) => {
              if (a.fcSchdlStrYmd < b.fcSchdlStrYmd) return -1
              if (b.fcSchdlStrYmd < a.fcSchdlStrYmd) return 1
              if (a.fcSchdlEndYmd > b.fcSchdlEndYmd) return -1
              if (b.fcSchdlEndYmd > a.fcSchdlEndYmd) return 1
              if (a.fcSchdlStrYmd === b.fcSchdlStrYmd && a.fcSchdlEndYmd === b.fcSchdlEndYmd) {
                if (a.fcSchdlStrHm < b.fcSchdlStrHm) return -1
                if (b.fcSchdlStrHm < a.fcSchdlStrHm) return 1
              }
              if (a.fcSchdlNm < b.fcSchdlNm) return -1
              if (b.fcSchdlNm < a.fcSchdlNm) return 1
            })
            let sendData = lv_Vm.fn_MakeString()
            window.fdpbridge.exec('setAppWidgetDataPlugin', { data: sendData }, () => {}, () => {})
          } else {
            lv_Vm.aPDaySchdlListSVO = []
            window.fdpbridge.exec('setAppWidgetDataPlugin', { data: 'null' }, () => {}, () => {})
          }
          // console.log(lv_Vm.aPDaySchdlListSVO)
        })
    },
    /******************************************************************************
    * Function명 : fn_MakeString
    * 설명       : 펼쳐진 화면에 나타날 데이터 세팅
    ******************************************************************************/
    fn_MakeString () {
      if (this.DEBUG) console.log('■MSPAP301M■■■■■■■■■ fn_MakeString')
      let ret = ''
      let lv_Vm = this
      for (let i = 0; i < lv_Vm.aPDaySchdlListSVO.length; i++) {
        let campaign = lv_Vm.aPDaySchdlListSVO[i].cntcObjCmpgnNm === undefined || lv_Vm.aPDaySchdlListSVO[i].cntcObjCmpgnNm === '' ? 'null' : lv_Vm.aPDaySchdlListSVO[i].cntcObjCmpgnNm
        let tmpLoca = lv_Vm.fn_MakeSchedulString('where', lv_Vm.aPDaySchdlListSVO[i]) === '' ? '장소 미지정' : lv_Vm.fn_MakeSchedulString('where', lv_Vm.aPDaySchdlListSVO[i])
        ret = ret + lv_Vm.aPDaySchdlListSVO[i].fcSchdlScCd + 'tsssmpA' + lv_Vm.aPDaySchdlListSVO[i].fcSchdlNm + 'tsssmpA' + lv_Vm.fn_MakeSchedulString('when', lv_Vm.aPDaySchdlListSVO[i]) + 'tsssmpA' + tmpLoca + 'tsssmpA' + campaign + 'tsssmpB'
      }
      return ret === '' ? 'null' : ret
    },
    /******************************************************************************
    * Function명 : fn_MakeSchedulString
    * 설명       : 일정의 시간 및 만날장소에 대한 문자열 Control
    ******************************************************************************/
    fn_MakeSchedulString ( code, data) {
      if (this.DEBUG) console.log('■MSPAP301M■■■■■■■■■ fn_MakeSchedulString')
      let ret_val = ''
      let lv_Vm = this
      if ( code === 'where' ) {
        if ( data.fcSchdlPrmsPlcNm !== null && data.fcSchdlPrmsPlcNm !== '' ) {
          ret_val = lv_Vm.$bizUtil.omitStr( data.fcSchdlPrmsPlcNm, 26 )
        }
      } else if ( code === 'when' ) {
        if ( data.fcSchdlStrYmd === data.fcSchdlEndYmd ) {
          if ( data.fcSchdlStrHm === null || data.fcSchdlEndHm === null ) {
            ret_val = '종일'
          } else {
            ret_val = lv_Vm.fn_ConvertTime(data.fcSchdlStrHm) + ' ~ ' + lv_Vm.fn_ConvertTime(data.fcSchdlEndHm)
            if (process.env.NODE_ENV !== 'local') {
            console.log('data.fcSchdlStrHm', data.fcSchdlStrHm)
            console.log('data.fcSchdlStrHm', data.fcSchdlStrHm)
            }
          }
        } else {
          ret_val = data.fcSchdlStrYmd + ' ' + lv_Vm.fn_ConvertTime(data.fcSchdlStrHm) + ' \n~ ' + data.fcSchdlEndYmd + ' ' + lv_Vm.fn_ConvertTime(data.fcSchdlEndHm)
        }
      }
      return ret_val
    },
    /*********************************************************
     * Function명: fn_ConvertTime
     * 설명: 시간데이터 한글화
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_ConvertTime ( data ) {
      var ret_val = ''
      if ( data === null || data.length !== 4 ) {
        ret_val = ''
      } else {
        if ( Number( data.substring(0, 2)) < 12 ) {
          ret_val = '오전 ' + data.substring(0, 2) + ':' + data.substring(2, 4)
        } else if ( Number( data.substring(0, 2)) > 12 ) {
          let hh = ( Number(data.substring(0, 2)) - 12 )
          hh = ( hh > 9 ? hh : '0' + hh )
          ret_val = '오후 ' + hh + ':' + data.substring(2, 4)
        } else {
          ret_val = '오후 ' + '12' + ':' + data.substring(2, 4)
        }
      }
      return ret_val
    },
    /******************************************************************************
    * Function명 : fn_PeriodChanged
    * 설명       : 기간이 변경되었을 때 처리하는 Function
    *            : 기간 관련 변수
    *             displayFirstDate - 달력의 첫날
    *             displayLastDate - 달력의 마지막날
    *             lv_PriodStart - 해당 월의 첫날
    *             lv_PeriodEnd - 해당 월의 마지막날
    ******************************************************************************/
    fn_PeriodChanged (date) {
      if (this.DEBUG) console.log('■MSPAP301M■■■■■■■■■ fn_PeriodChanged')
      this.lv_ChangeMonth = date
      // if (this.DEBUG) debugger
      // 시작하자마자 불리며 이 객체에서 사용할 기본 정보를 세팅함.
      if (this.DEBUG) console.log(date)
      this.lv_StartSchdDay = moment(date.displayFirstDate).format('YYYYMMDD')
      this.lv_EndSchdDay = moment(date.displayLastDate).format('YYYYMMDD')
      this.lv_PriodStart = moment(date.periodStart).format('YYYYMMDD')
      this.lv_PeriodEnd = moment(date.periodEnd).format('YYYYMMDD')
      // 최초 오늘로 스크롤 생기게 하기 위함.
      if (this.DEBUG) window.console.log(' ------------------------------- fn_PeriodChanged ------------------------------------' + this.lv_StartSchdDay)
      if (this.DEBUG) window.console.log(' ------------------------------- fn_PeriodChanged ------------------------------------' + this.lv_EndSchdDay)
      if (this.DEBUG) window.console.log(' ------------------------------- fn_PeriodChanged ------------------------------------' + this.lv_PriodStart)
      // 시작하자마자 불리기 때문에 mounted or created에 작성 불필요, 위에서 세팅된 기본정보를 기반으로 데이터객체를 불러옴.
      this.fn_SelectHolidayList()
      this.fn_SelectListPrdSchdl()
      if (this.lv_FromPeriod) {
        this.lv_Ignore = true
        this.lv_FocusDate = new Date(this.lv_ChoiceYmd)
        this.lv_FromPeriod = false
      }
      this.lv_ChoiceYmd = moment(this.lv_Today).format('YYYY-MM-DD')
    },
    /*********************************************************
     * Function명: fn_SelectHolidayList
     * 설명: 휴일 목록을 조회한다.
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SelectHolidayList () {
      if (this.DEBUG) console.log('■MSPAP301M■■■■■■■■■ fn_SelectHolidayList')
      let lv_Vm = this
      if (lv_Vm.lv_NowLoadingHoliday) return
      this.lv_NowLoadingHoliday = true
      lv_Vm.lv_HolidayList = []
      let trnstId = 'txTSSAP03S2'
      let pParam = {
        inqrStrYmd: lv_Vm.lv_StartSchdDay,
        inqrEndYmd: lv_Vm.lv_EndSchdDay
      }
      lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
        if (lv_Vm.DEBUG) console.log(response)
        if ( response.body != null) {
          lv_Vm.iCYmdMngVO = response.body.iCYmdMngVO
          if ( lv_Vm.iCYmdMngVO != null) {
            for (let j = 0; j < lv_Vm.iCYmdMngVO.length; j++) {
              let tmpObj = {} // holidays로 전달될 객체
              tmpObj.slrcYmYmd = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.iCYmdMngVO[j].slrcYmYmd)
              tmpObj.holyCd = lv_Vm.iCYmdMngVO[j].holyCd
              tmpObj.holyNm = lv_Vm.iCYmdMngVO[j].holyNm
              lv_Vm.lv_HolidayList.push(tmpObj)
              if (tmpObj.holyCd === '1') {
                let tmpObj2 = {} // 모바일에서는 일정처럼 취급하기위해 스케쥴러에 들어갈 객체에 추가로 넣어줌.
                tmpObj2.startDate = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.iCYmdMngVO[j].slrcYmYmd)
                tmpObj2.endDate = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.iCYmdMngVO[j].slrcYmYmd)
                tmpObj2.title = lv_Vm.iCYmdMngVO[j].holyNm
                tmpObj2.classes = 'yellow-event'
                lv_Vm.lv_SchdlList2.push(tmpObj2)
              }
            }
            if (process.env.NODE_ENV !== 'local') {
            console.log('lv_Vm.lv_SchdlList2', lv_Vm.lv_SchdlList2)
            }
          }
        }
        lv_Vm.lv_NowLoadingHoliday = false
      })
    },
    /*********************************************************
     * Function명: fn_SelectListPrdSchdl
     * 설명: 일정을 조회한다.
     * Params: N/A
     * 변수 : lv_SchdlList 301M에서 사용하기 위한 객체의 리스트.
     *        lv_SchdlList : tmpObj 리스트
     *         tmpObj : {
     *                  startDate         : '2019-01-01'  시작일 YYYY-mm-dd
     *                  endDate           : '2019-01-01'  종료일 YYYY-mm-dd ex)2019-01-01
     *                  title             : '제목'
     *                  subTitle          : ''            퍼블에서 넣어놓은것.
     *                  type              : 1             1: 영업일정(blue), 2: 개인일정(purple)
     *                  startHm           : 1900          hhmm
     *                  endHm             : 2000          hhmm
     *                  location          : '장소'
     *                  campaign          : '고객배정캠페인명'
     *                  lv_FcSchdlSno        : 'fc일정 일련번호'
     *                  lv_MobslChnlCustId   : ''            모바일 영업채널고객ID
     *                  lv_CustAlctnCmpgnId  : '고객배정캠페인ID'
     *                  classes           : '적용되어야할 클래스.'공휴일/음력표시 날짜가 겹칠 경우 공휴일 표기
     *                  }
     * Return: N/A
     *********************************************************/
    fn_SelectListPrdSchdl () {
      if (this.DEBUG) console.log('■MSPAP301MM■■■■■■■■■ fn_SelectListPrdSchdl')
      let lv_Vm = this
      if (lv_Vm.lv_NowLoadingPrdSchdl) return
      this.lv_NowLoadingPrdSchdl = true
      lv_Vm.lv_SchdlList2.length = 0
      // lv_Vm.lv_SchdlList.length = 0
      lv_Vm.lv_SchdlList2 = []
      lv_Vm.lv_SchdlList.splice(0, this.lv_SchdlList.length)
      var trnstId = 'txTSSAP20S3'
      let tUserEno = this.getStore('userInfo').getters.getUserInfo.userId
      var pParam = {
        userEno: tUserEno,
        fcSchdlStrYmd: lv_Vm.lv_StartSchdDay,
        fcSchdlEndYmd: lv_Vm.lv_EndSchdDay
      }
      lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
        if (lv_Vm.DEBUG) console.log('ㅁMSPAP301Mㅁfn_SelectListPrdSchdlㅁㅁㅁㅁ일정조회', response)
        if (response.msgComm.msgCd === 'EFWS004') {
          // 실패시
          window.fdpbridge.exec('ToastPlugin', {message: response.msgComm.msgDesc}, () => {}, () => {})
        }
        if ( response.body != null) {
          lv_Vm.aPSchdlInfoSVO = response.body.aPSchdlInfoSVO
          console.log('lv_Vm.aPSchdlInfoSVO', lv_Vm.aPSchdlInfoSVO)
          if ( response.body.aPSchdlInfoSVO != null) {
            for (let j = 0; j < lv_Vm.aPSchdlInfoSVO.length; j++) {
              let tmpObj = {} // 301M에서 사용될 객체
              let tmpObj2 = {} // fdp-Scheduler에 사용될 객체
              tmpObj2.startDate = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.aPSchdlInfoSVO[j].fcSchdlStrYmd)
              tmpObj2.endDate = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.aPSchdlInfoSVO[j].fcSchdlEndYmd)
              tmpObj2.title = lv_Vm.aPSchdlInfoSVO[j].fcSchdlNm
              if ( lv_Vm.aPSchdlInfoSVO[j].fcSchdlScCd === '1') {
                tmpObj2.classes = 'group-event'
              } else {
                tmpObj2.classes = 'personal-event'
              }
              tmpObj.startDate = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.aPSchdlInfoSVO[j].fcSchdlStrYmd)
              tmpObj.endDate = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.aPSchdlInfoSVO[j].fcSchdlEndYmd)
              tmpObj.title = lv_Vm.aPSchdlInfoSVO[j].fcSchdlNm
              tmpObj.subTitle = ''
              tmpObj.type = lv_Vm.aPSchdlInfoSVO[j].fcSchdlScCd
              tmpObj.startTime = lv_Vm.aPSchdlInfoSVO[j].fcSchdlStrHm
              tmpObj.endTime = lv_Vm.aPSchdlInfoSVO[j].fcSchdlEndHm
              tmpObj.place = lv_Vm.aPSchdlInfoSVO[j].fcSchdlPrmsPlcNm
              tmpObj.campaign = lv_Vm.aPSchdlInfoSVO[j].custAlctnCmpgnNm
              tmpObj.fcSchdlSno = lv_Vm.aPSchdlInfoSVO[j].fcSchdlSno
              tmpObj.mobslChnlCustId = lv_Vm.aPSchdlInfoSVO[j].mobslChnlCustId
              tmpObj.custAlctnCmpgnId = lv_Vm.aPSchdlInfoSVO[j].custAlctnCmpgnId
              tmpObj.getTitle = (t_obj) => {
                return t_obj.title
              }
              if ( lv_Vm.aPSchdlInfoSVO[j].fcSchdlScCd === '1') { // 1: 개인
                tmpObj.classes = ['group-event']
              } else if (lv_Vm.aPSchdlInfoSVO[j].fcSchdlScCd === '2') { // 2: 고객
                tmpObj.classes = ['personal-event']
              }
              lv_Vm.lv_SchdlList.push(tmpObj)
              lv_Vm.lv_SchdlList2.push(tmpObj2)
            }
          }
        }
        if (process.env.NODE_ENV !== 'local') {
        console.log('fn_SelectListPrdSchdl, lv_Vm.lv_SchdlList', lv_Vm.lv_SchdlList)
        }
        lv_Vm.lv_NowLoadingPrdSchdl = false
      }).catch(function (err) {
        if (lv_Vm.DEBUG) console.log('MSPAP301M===>err', err)
      })
      // lv_Vm.fn_SetScroll()
    },
    /*********************************************************
     * Function명: fn_SelectDate
     * 설명: 화면전환을 위해서 사용되거나, 특정일이 선택되었을때실행되는 메서드
     * Params: v
     * Return: N/A
     *********************************************************/
    fn_SelectDate (v) {
      if (this.DEBUG) console.log('■MSPAP301M■■■■■■■■■ fn_SelectDate')
      // if (this.DEBUG) debugger
      if (this.DEBUG) console.log(v)
      let lv_Vm = this
      if ( v.type !== 'click' ) {
        let tOriginDate = new Date(lv_Vm.lv_ChoiceYmd) // 원래의 날짜
        let tClickDate = new Date(v) // 클릭된 날짜.
        if (moment(tClickDate).format('YYYY-MM-DD') === moment(tOriginDate).format('YYYY-MM-DD') && moment(tClickDate).format('YYYY-MM-DD').slice(0, 7) === lv_Vm.$commonUtil.dateDashFormat(lv_Vm.lv_PriodStart).slice(0, 7)) { // 두번째 클릭인지 확인.
          if (!this.lv_Ignore) {
            if (!this.lv_IsDaily) {
              this.fn_OnChangeViewTest('calendar', tClickDate) // 한번 더 클릭했을때만 이동
              this.lv_FocusDate = tClickDate
            }
          }
        } else { // 처음 클릭시.
          if (tOriginDate.getFullYear() === tClickDate.getFullYear() && tOriginDate.getMonth() === tClickDate.getMonth() && moment(tClickDate).format('YYYY-MM-DD').slice(0, 7) === lv_Vm.$commonUtil.dateDashFormat(lv_Vm.lv_PriodStart).slice(0, 7)) {
            // 같은 연, 달이면 그대로 값을 넣음
            lv_Vm.lv_ChoiceYmd = moment(v).format('YYYY-MM-DD')
          } else {
            // 다르다면 연, 달을 변경시켜주고 선택일 변경.
            lv_Vm.lv_FromPeriod = true
            lv_Vm.lv_SelectedPeriod = new Date(v)
            lv_Vm.lv_ChoiceYmd = moment(v).format('YYYY-MM-DD')
          }
        }
      } else {
        this.$bizUtil.insSrnLog('MSPAP301M')
        // 캘린더의 연, 월을 리스트에 알리기 위함. 달이 변경되었으면 각 달의 1일을, 변경되지 않았으면 해당날짜를 설정.
        let tmpDate = lv_Vm.lv_PriodStart.slice(0, 4) + '-' + lv_Vm.lv_PriodStart.slice(4, 6) + '-' + lv_Vm.lv_PriodStart.slice(6, 8)

        if (new Date(lv_Vm.lv_ChoiceYmd).getFullYear() === new Date(tmpDate).getFullYear() && new Date(lv_Vm.lv_ChoiceYmd).getMonth() === new Date(tmpDate).getMonth()) {
          // lv_Vm.lv_ChoiceYmd = lv_Vm.lv_ChoiceYmd
        } else {
          lv_Vm.lv_ChoiceYmd = tmpDate
        }
        lv_Vm.lv_IsMSPAP301M = true
      }
      this.lv_Ignore = false
    },
    /*********************************************************
     * Function명: fn_NewSchedule
     * 설명: 일정추가버튼이 눌렸을때 실행될 메서드
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_NewSchedule () { // 302D로 화면 이동
      if (this.DEBUG) console.log('■MSPAP301M■■■■■■■■■ fn_NewSchedule')
      this.lv_FcSchdlSno = 0
      this.lv_MobslChnlCustId = ''
      this.lv_CustAlctnCmpgnId = ''
      this.lv_IsMSPAP301M = false
      this.lv_IsMSPAP302D = true
    },
    /*********************************************************
     * Function명: fn_Close
     * 설명: 자식들에게서 닫기버튼이 눌렸을때 실행되는 메서드
     * Params: v (301M에서 데이트값이 변했을경우 v로 해당일이 넘어옴.)
     * Return: N/A
     *********************************************************/
    fn_Close (v) { // 닫기
      if (this.DEBUG) console.log('■MSPAP302D■■■■■■■■■ fn_Close')
      if ( this.lv_Is301302 ) {
        this.lv_IsMSPAP301M = true
        this.lv_IsMSPAP302D = false
      } else {
        this.lv_IsMSPAP301M = true
        this.lv_IsMSPAP302D = false
      }
      if (v !== undefined) {
        this.lv_SelectedPeriod = new Date(v)
      }
    },
    /*********************************************************
     * Function명: fn_OnRefresh
     * 설명: 변경사항이 있을때 다시 화면을 조회하는 메서드
     * Params: comNm
     * Return: N/A
     *********************************************************/
    fn_OnRefresh (comNm) {
      if (this.DEBUG) console.log('■MSPAP301M■■■■■■■■■ fn_OnRefresh')
      if (comNm === 'MSPAP301M') {
        this.lv_IsMSPAP302D = false
        this.lv_IsMSPAP301M = false
      } else {
        this.lv_IsMSPAP302D = false
        this.lv_IsMSPAP301M = true
      }
      this.fn_SelectHolidayList()
      this.fn_SelectListPrdSchdl()
    },
    periodChanged(date) {
      // this.holidays = //서버에서 받아온 데이터
    },
    onScheduleModifyClick(dayEvent) {
      console.log('modify click', dayEvent);
    },
    /*********************************************************
     * Function명: fn_DeleteProcess
     * 설명: 삭제처리 프로세스
     * Params: data : N, Y
     * Return: N/A
     *********************************************************/
    fn_DeleteProcess ( data ) {
      if (this.DEBUG) console.log('■MSPAP301M■■■■■■■■■ fn_DeleteProcess')
      if ( data === 'N' ) {
        return null
      } else {
        var lv_Vm = this
        var trnstId = 'txTSSAP20D1'
        var pParam = {
          userEno: lv_Vm.getStore('userInfo').getters.getUserInfo.userId,
          fcSchdlSno: Number(lv_Vm.lv_ChoiceFcSchdlSno)
        }
        lv_Vm.post(lv_Vm, pParam, trnstId, 'D')
          .then(function (response) {
            if (lv_Vm.DEBUG) console.log(response)
            if (response.msgComm.msgCd === 'ECOU004') {
              lv_Vm.fn_BottomAlert('삭제되었습니다')
              lv_Vm.lv_ChoiceFcSchdlSno = 0
              lv_Vm.lv_ChoiceMobslChnlCustId = ''
              lv_Vm.fn_OnRefresh()
            }
          })
      }
    },
    /*********************************************************
     * Function명: fn_OnChangeView
     * 설명: 캘린더View -> DaliyView 전환 (날짜 포커스)
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OnChangeView (mode, clickDate) {
      this.fn_OnModeChange(true)
      let t_clickDate = moment(clickDate).format('D')
      console.log('clickDate', clickDate)
      console.log('clickDate', t_clickDate)
      if (mode === 'calendar') {
        console.log('changeMode')
         this.$refs.loginScheduler.changeMode('daily')
         this.$refs.loginScheduler.setShowDate(new Date((clickDate.getFullYear()), clickDate.getMonth() ))
        setTimeout(() => {
          this.$refs.loginScheduler.clickDate(clickDate, t_clickDate)
          this.$refs.loginScheduler.moveToDay(t_clickDate)
        })
      } else {
        this.$refs.loginScheduler.changeMode('calendar')
      }
    },
    /*********************************************************
     * Function명: fn_OnChangeViewTest
     * 설명: 캘린더View -> DaliyView 전환 (날짜 포커스)
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OnChangeViewTest (mode, clickDate) {
      let t_clickDate = moment(clickDate).format('D')
      console.log('clickDate', clickDate)
      console.log('clickDate', t_clickDate)
      if (mode === 'calendar') {
        console.log('changeMode')
         this.$refs.loginScheduler2.changeMode('daily')
        setTimeout(() => {
          this.$refs.loginScheduler.clickDate(clickDate, t_clickDate)
          this.$refs.loginScheduler.moveToDay(t_clickDate)
        })
      } else {
        this.$refs.loginScheduler.changeMode('calendar')
      }
    },
    /*********************************************************
     * Function명: fn_ChoiceSchedule
     * 설명: 스케쥴 Update, Delete를 처리하는 메서드
     * Params:
     *        procType : Update, Delete
     *        data : mock
     * Return: N/A
     *********************************************************/
    fn_ChoiceSchedule (procType, data) {
      if (this.DEBUG) console.log('■MSPAP301M■■■■■■■■■ fn_ChoiceSchedule')
      if (this.DEBUG) console.log(data)
      this.lv_ChoiceFcSchdlSno = Number(data.fcSchdlSno)
      this.lv_ChoiceMobslChnlCustId = data.mobslChnlCustId
      this.lv_ModChoicedYmd = data.dateYmd
      if ( procType === 'D') {
        this.lv_BDeleteConfirm = true
        this.fn_BottomConfirm('삭제하시겠습니까?')
      } else {
        this.lv_ChoiceFcSchdlSno = Number(data.fcSchdlSno)
        this.lv_ChoiceMobslChnlCustId = data.mobslChnlCustId
        this.lv_ModChoicedYmd = data.dateYmd
        this.fn_OpenMSPAP302D()
      }
    },
    /******************************************************************************
     * Function명 : fn_ModalOpen() / close()
     * 설명       : 일정삭제여부 Modal Open/Close
     ******************************************************************************/
    fn_ModalOpen() { this.$refs.refConfitmModal.open() },
    fn_ModalClose() { this.$refs.refConfitmModal.close() },
    /*********************************************************
     * Function명: fn_OpenMSPAP302D
     * 설명: 일정추가 / 수정 화면팝업 Open/Close
     * Params: pPage: 호출하는 화면ID
     *         pIsBackKeyListen: 
     *         pFcSchdlSno: 일정추가 / 삭제 판단여부
     *         pMobslChnlCustId: 채널고객ID
     *         pCustAlctnCmpgnId: 캠페인ID
     *         pChoiceYmd: 선택된 날짜
     *         pSrnId: 화면ID
     * Return: N/A
     *********************************************************/
    fn_OpenMSPAP302D () {
      console.log('ㅁㅁㅁㅁㅁMSPAP302D 호출')
      this.lv_Popup302 = this.$bottomModal.open(MSPAP302D, {
        properties: {
          pPage: 'MSPAP302D',
          pIsBackKeyListen: true,
          pFcSchdlSno: this.lv_ChoiceFcSchdlSno,
          pMobslChnlCustId: this.lv_ChoiceMobslChnlCustId,
          pCustAlctnCmpgnId: this.lv_CustAlctnCmpgnId,
          pChoiceYmd: this.lv_ChoiceYmd,
          pSrnId: 'MSPAP301M'
        },
        listeners: {
          closeDivision: () => {
            console.log('closeDivision')
            this.lv_ChoiceFcSchdlSno = 0
            this.$bottomModal.close(this.lv_Popup302)
          },
          onPopupClose: () => {
            console.log('onPopupClose')
            this.$bottomModal.close(this.lv_Popup302)
            this.fn_SelectHolidayList()
            this.fn_SelectListPrdSchdl()
          },
          fn_OnRefresh: () => {
              this.fn_SelectHolidayList()
              this.fn_SelectListPrdSchdl()
            }
          }
      })
    },
    /******************************************************************************
     * Function명 : fn_BottomAlert
     * 설명       : 알림 팝업 호출
     * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
     ******************************************************************************/
   fn_BottomAlert (contents) {
     this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         single: true,
         title: '알림',
         content: contents
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
         },
         onPopupClose: () => {
           this.$bottomModal.close(this.lv_AlertPop);
         }
       }
     })
   },
    /******************************************************************************
     * Function명 : fn_BottomConfirm
     * 설명       : Confirm 팝업 호출(확인, 취소)
     * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
     ******************************************************************************/
   fn_BottomConfirm (contents) {
     this.lv_ConfirmPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: '알림',
         content: contents,
         title_pos_btn: "확인",
         title_neg_btn: "취소"
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_ConfirmPop);
           this.fn_DeleteProcess('Y')
         },
         onPopupClose: () => {
           this.$bottomModal.close(this.lv_ConfirmPop);
           this.fn_DeleteProcess('N')
         }
       }
     })
   },
    /*********************************************************
     * Function명: fn_OnModeChange
     * 설명: Calendar View, Daily View 전환.
     * Params: N/A
     * Return: N/A
     *********************************************************/
  fn_OnModeChange (event) {
    if (this.lv_IsFirst === true) {
        if (event) {
          this.lv_IsDaily = true
          this.lv_IsCalendar = false
          let t_moveDate = moment(this.lv_Today).format('D')
          this.$nextTick(() => {
            this.$refs.loginScheduler.clickDate(this.lv_Today, t_moveDate)
            this.$refs.loginScheduler.moveToDay(t_moveDate)
          })
      } else {
          this.lv_IsCalendar = true
          this.lv_IsDaily = false
         this.$refs.loginScheduler.changeMode('calendar')
      }
    } else {
        if (event) {
          this.lv_IsDaily = true
          this.lv_IsCalendar = false
      } else {
          this.lv_IsCalendar = true
          this.lv_IsDaily = false
          this.$refs.loginScheduler.changeMode('calendar')
      }
    }
    if (process.env.NODE_ENV !== 'local') {
    console.log('this.lv_IsCalendar', this.lv_IsCalendar)
    console.log('this.lv_IsDaily', this.lv_IsDaily)
    }
  },
    /*********************************************************
     * Function명: fn_SetHoliDay
     * 설명: fn_selectHolidayList으로 세팅된 holidays의 주말, 휴일값들을 301화면에 맞게 lv_301HolidayList으로수정.
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SetHoliDay () {
      let lv_Vm = this
      lv_Vm.lv_301HolidayList.length = 0
      lv_Vm.lv_301HolidayList = [{}]
      for ( let i = 1; i < 32; i++) {
        for ( let j = 0; j < lv_Vm.lv_Holidays.length; j++) {
          let tmpDate = lv_Vm.lv_Holidays[j].date
          if (new Date(lv_Vm.lv_Holidays[j].date).getFullYear() === new Date(lv_Vm.lv_ChoiceYmd).getFullYear() && new Date(lv_Vm.lv_Holidays[j].date).getMonth() === new Date(lv_Vm.lv_ChoiceYmd).getMonth()) {
            if (Number(tmpDate.slice(8, 10)) === i) {
              lv_Vm.lv_301HolidayList[i] = lv_Vm.lv_Holidays[j]
            }
          }
        }
      }
      if (process.env.NODE_ENV !== 'local') {
      console.log('fn_SetHoliDay.lv_Vm.lv_301HolidayList', lv_Vm.lv_301HolidayList)
      }
    },
    /*********************************************************
     * Function명: fn_CalendatTouchStart
     * 설명: 캘린더 스와이프 기능
     *       2022-08-22 운영계에서 DaliyView ->Calendar View 전환 시 2달 씩 이동하는 현상 발행하여 해당 부분을 주석처리 함(CI검증계에서 재현이 불가하고, 운영계에서만 발생)
     *       김미정 프로와 협의 결과 추후 반영으로 주석처리 함.
     *      <mo-scheduler> props에 @touchstart 추가 후 fn_CalendatTouchStart를 추가하여 사용할 수 있다.
     * Params: N/A
     * Return: N/A
     
    fn_CalendatTouchStart (e) {
      this.lv_TouchStart.x = e.changedTouches[0].screenX
      this.lv_TouchStart.y = e.changedTouches[0].screenY;
    },
    *********************************************************/
    /*********************************************************
     * Function명: fn_CalendatTouchEnd
     * 설명: 캘린더 스와이프 기능
     *       2022-08-22 운영계에서 DaliyView ->Calendar View 전환 시 2달 씩 이동하는 현상 발행하여 해당 부분을 주석처리 함(CI검증계에서 재현이 불가하고, 운영계에서만 발생)
     *       김미정 프로와 협의 결과 추후 반영으로 주석처리 함.
     *      <mo-scheduler> props에 @touchend 추가 후 fn_CalendatTouchEnd를 추가하여 사용할 수 있다.
     *      setShowDate() -> node_modules/uidev-compo-mobile/moSCheduler.vue에서 확인 가능.
     *      Touch 시작점과 Touch 종료점의 좌표를 확인하여 오른쪽, 왼쪽 스와이프 시 1달 씩 이동하도록 기능을 구현
     * Params: N/A
     * Return: N/A

    fn_CalendatTouchEnd (e) {
      this.lv_TouchEnd.x = e.changedTouches[0].screenX;
      this.lv_TouchEnd.y = e.changedTouches[0].screenY;
      if(this.lv_TouchStart.x < this.lv_TouchEnd.x && Math.abs(this.lv_TouchEnd.x - this.lv_TouchStart.x) > 50 && Math.abs(this.lv_TouchStart.y - this.lv_TouchEnd.y) < 20) {
       try {
         this.$refs.loginScheduler.setShowDate(new Date((this.lv_ChangeMonth.periodEnd.getFullYear()), this.lv_ChangeMonth.periodEnd.getMonth() -1))
       }
       catch {}
       try {
         this.$refs.loginScheduler2.setShowDate(new Date((this.lv_ChangeMonth.periodEnd.getFullYear()), this.lv_ChangeMonth.periodEnd.getMonth() -1))
       }
       catch {}
      } else if (this.lv_TouchStart.x > this.lv_TouchEnd.x && Math.abs(this.lv_TouchStart.x - this.lv_TouchEnd.x) > 50 && Math.abs(this.lv_TouchStart.y - this.lv_TouchEnd.y) < 20) {
        try {
          this.$refs.loginScheduler.setShowDate(new Date((this.lv_ChangeMonth.periodEnd.getFullYear()), this.lv_ChangeMonth.periodEnd.getMonth() +1))
        }
        catch {}
        try {
          this.$refs.loginScheduler2.setShowDate(new Date((this.lv_ChangeMonth.periodEnd.getFullYear()), this.lv_ChangeMonth.periodEnd.getMonth() +1))
        }
        catch {}
      }
    }
    *********************************************************/
  } // method end
 };
</script>
<style>
.MSPAP301M-height .mo-page__contents{
  height: 100%;
}
</style>